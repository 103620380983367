/* eslint-disable max-len */
import * as React from 'react'

type NavLogoProps = {
  href?: string
  height?: string | number
}

export const NavLogo = ({
  href,
  height,
}: NavLogoProps) => (
  <a
    href={href || '/'}
    style={{ display: 'flex' }}
  >
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 621 153'
      height={height}
    >
      <g fill='#0a1128'>
        <path d='M213.94,76.25c0-15.47.02-30.95-.03-46.42,0-1.37.31-1.73,1.7-1.71,6.1.09,12.21.08,18.31,0,1.29-.02,1.61.29,1.61,1.6-.04,27.41-.02,54.81-.07,82.22,0,1.49.45,1.78,1.81,1.74,2.87-.1,5.74-.14,8.61,0,6.19.28,11.01-5.28,10.97-10.96-.16-24.28-.06-48.56-.11-72.84,0-1.43.37-1.77,1.77-1.75,6.21.08,12.42.06,18.62,0,1.05,0,1.27.3,1.27,1.3-.03,19.75.06,39.49-.06,59.24-.06,9.75-3.81,17.98-10.75,24.96-7.45,7.5-16.32,11.02-26.8,10.92-8.4-.08-16.8-.06-25.2.02-1.44.01-1.68-.41-1.68-1.74.05-15.53.03-31.05.03-46.58Z' />
        <path d='m470.95 87c0-5.42 0.02-10.84 0-16.26-0.04-11.29 9.66-21.12 20.9-21.24 7.25-0.08 14.51-0.19 21.76-0.02 12.72 0.28 21.69 9.43 21.81 22.17 0.1 10.32 0.09 20.64 0 30.96-0.1 11.22-7.57 19.97-18.4 21.74-1.12 0.18-2.29 0.18-3.43 0.19-7.15 0.01-14.3 0-21.44 0-11.24-0.01-20.85-9.25-21.15-20.48-0.15-5.68-0.03-11.36-0.03-17.04h-0.01zm21.59 0.11v15.95c0 5.95 4.66 10.59 10.62 10.59 5.87 0 10.63-4.6 10.66-10.42 0.04-10.79 0.04-21.58 0-32.36-0.03-5.85-4.92-10.53-10.81-10.47-5.79 0.05-10.46 4.79-10.47 10.62v16.1z' />
        <path d='M342.27,116.35c-.31.8-.59,1.61-.93,2.39-1.43,3.37-4.03,5.47-7.62,5.65-5.51.26-11.07.61-16.56-.21-9.52-1.43-17.41-10.37-17.49-20.01-.13-17.87-.04-35.75-.07-53.63,0-.89.26-1.12,1.13-1.11,6.42.04,12.84.06,19.26-.01,1.39-.02,1.17.76,1.17,1.57,0,17.25,0,34.5,0,51.75,0,5.22,3.16,9.38,7.98,10.62,6.66,1.72,13.27-3.22,13.3-10.1.07-17.41.05-34.81.02-52.22,0-1.27.25-1.64,1.59-1.63,6.16.08,12.32.06,18.47.01,1.08,0,1.52.13,1.51,1.4-.04,24.13-.04,48.26,0,72.39,0,1.14-.36,1.36-1.41,1.35-6.26-.05-12.52-.05-18.79,0-1.11,0-1.41-.29-1.37-1.38.09-2.26.03-4.52.03-6.79-.07-.02-.15-.03-.22-.05Z' />
        <path d='M578.11,57.84c.56-.29.49-.82.63-1.23,1.35-4.02,3.97-6.68,8.31-6.95,5.72-.36,11.49-.77,17.15.39,9.44,1.93,16.66,10.58,16.73,20.2.12,17.72.04,35.43.06,53.15,0,.84-.16,1.17-1.09,1.16-6.42-.04-12.84-.06-19.25.01-1.38.02-1.19-.73-1.19-1.56,0-17.14,0-34.29,0-51.43,0-5.37-3.29-9.75-8.1-10.88-6.92-1.63-13.22,3.43-13.23,10.71-.03,17.04,0,34.08-.01,51.12q0,2.01-2.08,2.01c-6,0-12-.04-18,.03-1.2.01-1.44-.32-1.44-1.47.04-24.07.04-48.15,0-72.22,0-1.14.22-1.48,1.43-1.47,6.26.07,12.52.06,18.78,0,1.14-.01,1.37.34,1.34,1.4-.08,2.32-.02,4.65-.02,7.03Z' />
        <path d='M406.76,76.35c0,15.47-.03,30.94.04,46.41,0,1.57-.45,1.83-1.89,1.81-6.05-.09-12.1-.06-18.15-.01-1.15,0-1.6-.18-1.59-1.5.04-31.15.04-62.3,0-93.45,0-1.14.24-1.49,1.44-1.48,6.26.07,12.52.07,18.78,0,1.27-.02,1.39.43,1.39,1.5-.03,15.58-.02,31.15-.02,46.73Z' />
        <path d='M449.65,87.14c0,11.93-.02,23.85.03,35.78,0,1.23-.19,1.67-1.56,1.65-6.2-.09-12.41-.06-18.62-.01-1.09,0-1.49-.15-1.49-1.4.05-24.12.05-48.23,0-72.35,0-1.25.4-1.4,1.49-1.4,6.21.05,12.41.07,18.62-.01,1.37-.02,1.57.41,1.56,1.64-.05,12.03-.03,24.06-.03,36.09Z' />
        <path d='M438.85,39.06c-3.23,0-6.47-.03-9.7.02-.89.01-1.15-.25-1.14-1.14.05-2.92.05-5.83,0-8.75-.01-.8.19-1.06,1.03-1.05,6.52.03,13.04.03,19.56,0,.8,0,1.07.18,1.06,1.03-.05,2.92-.07,5.83,0,8.75.03,1.05-.39,1.17-1.27,1.16-3.18-.04-6.36-.02-9.54-.02Z' />
        <rect
          width='153'
          height='153'
          rx='15.52'
          ry='15.52'
        />
      </g>
      <g fill='#fefcfb'>
        <path d='m120.49 22.109c-12.02 0.12-24.05 0.15125-36.07 0.03125-15.01-0.15-27.699 4.8889-38.369 15.629-9.8125 9.8818-15.175 21.519-15.367 35.281-0.001362-8.9e-5 -0.002544 9e-5 -0.003906 0v0.11719c-0.00132 0.1115-0.009126 0.22026-0.009766 0.33203 0 1.38 2.34e-4 2.7509-0.009766 4.1309 0.006507-5.2e-5 0.013025 5.2e-5 0.019532 0 0.001021 16.655 0.006133 33.315-0.048829 49.969 0 1.9 0.33844 2.5102 2.3984 2.4902 12.02-0.12 24.05-0.14929 36.07-0.02929 15.01 0.15 27.701-4.8909 38.371-15.631 9.8121-9.8814 15.172-21.518 15.365-35.279 0.00136 8.9e-5 0.00254-9e-5 0.0039 0v-0.11914c0.00132-0.11141 0.00913-0.22034 0.00977-0.33203 0-1.38-2.3e-4 -2.7489 0.00977-4.1289-0.00651 5.3e-5 -0.01303-5.1e-5 -0.01954 0-0.00102-16.655-0.00418-33.317 0.05078-49.971 0-1.9-0.34039-2.5102-2.4004-2.4902zm-31.029 15.59c1.95-0.07 2.5998 0.35024 2.5898 2.4902-0.018139 11.437-0.027961 22.874-0.037109 34.311-0.011069-5.4e-5 -0.022134 5.4e-5 -0.033203 0 0.02 8.11 0.039609 16.211 0.099609 24.311 0.06 8.13-6.8392 16.08-15.699 15.68-4.1-0.19-8.2203-0.13023-12.32 0.00977-1.95 0.07-2.5998-0.35024-2.5898-2.4902 0.018139-11.437 0.026008-22.874 0.035156-34.311 0.011073 5.3e-5 0.022131-5.4e-5 0.033203 0-0.019999-8.11-0.039609-16.209-0.099609-24.309-0.06-8.13 6.8412-16.08 15.701-15.68 4.1 0.19 8.2203 0.12828 12.32-0.011719z' />
      </g>
    </svg>
  </a>
)

export default NavLogo
